<template>
    <span>
        <LoadingImg v-if="loading.list" />
        <div class="ol-dashboard__banks">
            <h4 class="ol-dashboard__container-title">Daftar Rekening
                <a @click="showPopup(true)" class="ol-btn ol-dashboard__address-bank-add" style="float:right;font-size:13px;">+ Tambah Bank</a>
            </h4>
            <div class="ol-checkout-content__shipping ol-dashboard__address-shipping ol-dashboard__address-bank">
                <div class="ol-checkout-content__shipping-list">
                    <Alert v-show="alertList.message !=null " :type="alertList.type" :message="alertList.message"/>

                    <div class="ol-checkout-content__shipping-list-head">
                        <div class="ol-checkout-content__shipping-list-head-item">
                            &nbsp;
                        </div>
                        <div class="ol-checkout-content__shipping-list-head-item">
                            Bank
                        </div>
                        <div class="ol-checkout-content__shipping-list-head-item">
                            Nama
                        </div>
                        <div class="ol-checkout-content__shipping-list-head-item">
                            Aksi
                        </div>
                    </div>
                    <div class="ol-checkout-content__shipping-list-body">
                        <transition-group name="fade">
                            <label v-for="bankNumb in numbers" :key="bankNumb.id" class="ol-checkout-content__shipping-list-body-wrapper active">
                                <div class="ol-checkout-content__shipping-list-body-item">
                                    &nbsp;
                                </div>
                                <div class="ol-checkout-content__shipping-list-body-item">
                                    <figure>
                                        <img :src="bankNumb.icon_url" :alt="bankNumb.bank" :type="bankNumb.bank">
                                    </figure>
                                </div>
                                <div class="ol-checkout-content__shipping-list-body-item">
                                    <p>{{bankNumb.name}}</p>
                                    <span>{{bankNumb.number}}</span> 
                                </div>
                                <div class="ol-checkout-content__shipping-list-body-item">
                                    <a title="Edit" v-on:click="edit(bankNumb.id)"><i class="far fa-edit"></i></a>
                                    <a title="Hapus" v-on:click="del(bankNumb.id)"><i class="fas fa-trash"></i></a>
                                </div>
                            </label>
                        </transition-group>
                        <label v-show="numbers.length<1" class="ol-checkout-content__shipping-list-body-wrapper">
                            Belum ada Bank yang disimpan
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <Popup :title="title">
			<LoadingImg v-if="loading.save" />
            <form v-on:submit.prevent="save()">
                <Alert v-show="alert.message !=null" :type="alert.type" :message="alert.message"/>

                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Bank</label>
                    <select class="ol-input ol-input-select ol-input--select2" id="bank" >
                        <option value=""></option>
                        <option v-for="bank in banks" :key="bank.id" :value="bank.id">{{bank.name}}</option>
                    </select>
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">Nama</label>
                    <input type="text" id="pemilik" v-model="input.name" class="ol-input">
                </div>
                <div class="ol-input-box ol-input-box--half">
                    <label class="ol-input-label">No Rekening</label>
                    <input type="number" id="rek" v-model="input.number" class="ol-input" >
                    
                </div>
                <div class="ol-input-box ol-input-box--full">
                    <input type="submit" name="" class="ol-btn" value="Simpan">
                </div>
            </form>
        </Popup>
    </span>
</template>

<script>
import Popup from '@/components/popup/Popup.vue'
import { mapGetters,mapActions } from 'vuex'
export default {
    name:"BankAccounts",
    components:{
        Popup,
    },
    data(){
        return{
            title:null,
            input:{
                bank:null,
                name:null,
                number:null,
            },
			loading:{
                list:true,
                save:false,
            },
            alert:{
                message:null,
                type:"info",
            },
            alertList:{
				message:null,
				type:"info",
			},
            maxNumber:{
                bri:14,
                bca:9,
                mdb:9,
                bj:9,
                bni:9,
                permata:9,
                danamon:9,
                cimb:12,
                mandiri:12,
                uob:15,
            },
        }
    },
    computed:{
        ...mapGetters('profile/bank', ['banks','numbers']),
    },
    methods:{
        ...mapActions('profile/bank', ['fetchBanks','fetchNumbers','saveNumber','updateNumber','delNumber']),

        showPopup(show=true,isNew=true){  
            this.title="Edit bank";
            if(isNew){
                $("select#bank").val(null).trigger("change");
                this.title="Tambah bank";
                this.input.id=null;
                this.input.bank=null;
                this.input.name=null;
                this.input.number=null;
            }
            (show)? Popup.methods.show():Popup.methods.hide();
            this.loading.save = false;
        },
        
        async save(){
            this.alertList.message=null;
            this.alert.message=null;
            this.input.bank = $("select#bank").val();
            if(!this.input.bank){
                this.alert.message = "Kolom Bank wajib dilengkapi!";
                this.alert.type = "danger";
            }else if(!this.input.name){
                this.alert.message = "Kolom Nama wajib dilengkapi!";
                this.alert.type = "danger";
            }else if(!this.input.number){
                this.alert.message = "Kolom Rekening wajib dilengkapi!";
                this.alert.type = "danger";
            }else{
                this.loading.save = true;
                let isSuccess = (!this.input.id) ? await this.saveNumber(this.input) : await this.updateNumber(this.input);
                if(isSuccess){
                    this.showPopup(false)  
                    this.alertList.message="Berhasil menyimpan akun bank"
                    this.alertList.type="success"
                }else{
                    this.alert.message = "Gagal menyimpan akun bank, coba beberapa saat lagi.";
                    this.alert.type = "danger";
                    this.loading.save = false;
                }
            }
        },
        edit(id){
            const bankNumb = _.find(this.numbers,{"id":id});
            this.input.id = bankNumb.id;
            this.input.bank = bankNumb.uni_bank_id;
            this.input.name = bankNumb.name;
            this.input.number = bankNumb.number;
            $("select#bank").val(this.input.bank).trigger("change");
            this.showPopup(true,false);
        },
        del(id){
            this.alertList.message=null;
            Swal.fire({
                text: "Yakin ingin dihapus?",
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#364b7b',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    this.loading.list = true;
                    const isSuccess = await this.delNumber(id);
                    if(isSuccess){
                        this.alertList.message="Berhasil menghapus data"
                        this.alertList.type="success"
                        this.loading.list = false;  
                    }else{
                        this.alertList.message="Gagal menghapus data, coba beberapa saat lagi"
                        this.alertList.type="danger"
                        this.loading.list = false;  
                    }

                }
            })
        },

    },
    async mounted(){
        $("select#bank").select2({
            placeholder: "-- Pilih Opsi --",
            allowClear: true
        })
        await this.fetchNumbers() ? this.loading.list=false : this.$toast.open({ message: "Gagal mendapatkan data akun bank", type: "error", duration: 3000, });
        await this.fetchBanks() ? this.loading.list=false : this.$toast.open({ message: "Gagal mendapatkan data bank", type: "error", duration: 3000, });
    }
}
</script>

<style>

</style>